import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
  Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { ConstanteService } from 'src/app/core/services/configuraciones/constante/constante.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  UrlVideoInstructivo: string;
  UrlManualUsuario: string;
  UrlTicketSoporte: string;
  Usuario: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private constanteService: ConstanteService
  ) {}

  ngOnInit(): void {
    this.EstablecerConstantePorTipo('ayuda_video_instructivo');
    this.EstablecerConstantePorTipo('ayuda_manual_usuario');
    this.EstablecerConstantePorTipo('ayuda_ticket_soporte');
    this.Usuario = JSON.parse(localStorage.getItem('user'));
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e: Event) {
    e.preventDefault();
    localStorage.removeItem('isLoggedin');
    localStorage.removeItem('processes');
    localStorage.removeItem('user');
    localStorage.removeItem('perfil');

    if (!localStorage.getItem('isLoggedin')) {
      this.router.navigate(['/auth/login']);
    }
  }

  EstablecerConstantePorTipo(tipoconstante: string) {
    this.constanteService.GetPorTipo(tipoconstante).subscribe((r) => {
      if (
        typeof r === 'object' &&
        r.valor &&
        tipoconstante === 'ayuda_video_instructivo'
      )
        this.UrlVideoInstructivo = r.valor;
      if (
        typeof r === 'object' &&
        r.valor &&
        tipoconstante === 'ayuda_manual_usuario'
      )
        this.UrlManualUsuario = r.valor;
      if (
        typeof r === 'object' &&
        r.valor &&
        tipoconstante === 'ayuda_ticket_soporte'
      )
        this.UrlTicketSoporte = r.valor;
    });
  }
}
